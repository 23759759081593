var format_currency = function(n, currency) {
    if (! currency) {
        currency = 'USD';
    }
    if (typeof(n) == 'number') {
        n = n.toFixed(2);
    }
    var x = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(n);
    return x;
};

var createSnackHTML = function(msg, toast_classes) {
    var s = `
        <div class="toast align-items-center ${toast_classes}" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    ${msg}
                </div>
            </div>
        </div>
    `;
    return s;
};

// helper
function is_tt_visible(show, ticketType, permissions) {
    var is_allowed = true;

    // this is necessary because all the other stuff isn't serialized if the ticket type is not enabled
    if (show['has_' + ticketType]) {
        // admins can see everything
        if (permissions['is_ticket_admin']) {
            is_allowed = true;
        } else {
            if (show['restricted_' + ticketType]) {
                is_allowed = false;
            } else {
                if (show['limited_' + ticketType + '_groups'].length > 0) {
                    is_allowed = (_.intersection(permissions['org_group_nids'], show['limited_' + ticketType + '_groups']).length > 0);
                }
            }
        }
    }

    var tt_visible = (show['has_' + ticketType] && !show['hide_' + ticketType] && is_allowed);

    return tt_visible;
}

function is_tt_visible_guest(show, ticketType, allow_restricted_ticket_types) {
    var is_allowed = true;

    // this is necessary because all the other stuff isn't serialized if the ticket type is not enabled
    if (show['has_' + ticketType]) {
        // this implies that it's a direct link or a request with specific preset ticket types
        if (show['restricted_' + ticketType] && !allow_restricted_ticket_types) {
            is_allowed = false;
        }

        if ((show['limited_' + ticketType + '_groups'].length > 0) && !allow_limited_ticket_types) {
            is_allowed = false;
        }
    }

    var tt_visible = (show['has_' + ticketType] && !show['hide_' + ticketType] && is_allowed);

    return tt_visible;
}

$(document).ready(function() {
    // browser update script
    var $buoop = {
        required: {
            e: -4,
            f: -5,
            o: -3,
            s: -2,
            c: -5
        },
        text: "Your browser is out of date and not supported by GuestTix. Your order may not work - please consider updating or using a different browser or device.<br><a{up_but}>Update Browser</a> <a{ignore_but}>Ignore</a>",
        insecure: true,
        api: 2021.08
    };
    $buo($buoop);

    // set CSRF protection
    var csrftoken = Cookies.get('csrftoken');
    function csrfSafeMethod(method) {
        // these HTTP methods do not require CSRF protection
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }
    $.ajaxSetup({
        crossDomain: false, // obviates need for sameOrigin test
        beforeSend: function(xhr, settings) {
            if (!csrfSafeMethod(settings.type)) {
                xhr.setRequestHeader("X-CSRFToken", csrftoken);
            }
        }
    });

    var guest_url_clipboard = new ClipboardJS('.guest-url-clipboard-trigger');
    guest_url_clipboard.on('success', function(e) {
        var id = $(e.trigger).data('tr-request-id');
        if (id) {
            $('#guest_url_' + id + '_action_feedback').html(' &nbsp; <b><i class="fa fa-check-circle gtx-icon-confirm"></i> Copied!');
        }
    });

    guest_url_clipboard.on('error', function(e) {
        var id = $(e.trigger).data('tr-request-id');
        var msg = '';

        if (/iPhone|iPad/i.test(navigator.userAgent)) {
            msg = 'Copying not supported (yet)';
        } else if (/Mac/i.test(navigator.userAgent)) {
            msg = 'Press ⌘-C to copy';
        } else {
            msg = 'Press Ctrl-C to copy';
        }

        if (id) {
            $('#guest_url_' + id + '_action_feedback').html(msg);
        }
    });

    var generic_clipboard = new ClipboardJS('.clipboard-trigger');
    generic_clipboard.on('success', function(e) {
        $(e.trigger).tooltip('show');
        setTimeout(function() {
            $(e.trigger).tooltip('hide');
        }, 1000);
    });

    generic_clipboard.on('error', function(e) {
        var msg = '';

        if (/iPhone|iPad/i.test(navigator.userAgent)) {
            msg = 'Copying not supported (yet)';
        } else if (/Mac/i.test(navigator.userAgent)) {
            msg = 'Press ⌘-C to copy';
        } else {
            msg = 'Press Ctrl-C to copy';
        }

        window.alert(msg);
    });


    $('.guest-url-resend').on('click', function(e) {
        e.preventDefault();
        var btn = $(this);
        var tour_id = $(this).data('tr-tour-id');
        var id = $(this).data('tr-request-id');
        $(btn).find('.loader').addClass('visible');
        $(btn).attr("disabled", "disabled");
        $.post('/staff/request/' + tour_id + '/' + id + '/resend/', function() {
            $('#guest_url_' + id + '_action_feedback').html(' &nbsp; <b><i class="fa fa-check-circle gtx-icon-confirm"></i> Email resent!</b>');
            $(btn).find('.loader').removeClass('visible');
            $(btn).removeAttr("disabled");
        });
    });
        
    // trigger a 0px scroll, to hide the address bar on mobile,
    // and trigger the scroll hint arrow
    setTimeout( function(){ window.scrollTo(0, 1); }, 50 );
    
    // add .form-control class to report form select boxes
    $('.report-form SELECT, .seating-filter-form SELECT').addClass('form-control');

    // make report form select boxes, or other dropdowns with the selector class, CHOSEN
    // use custom data-tr-chosen-* parameters to set width or other options
    $('.report-form SELECT, .seating-filter-form SELECT, SELECT.make-chosen').each(function(idx, el) {
        var opts = {"search_contains": true};
        var data = $(this).data();
        $.each(data, function(k, v) {
            var key = _.snakeCase(k);
            if (_.startsWith(key, 'tr_chosen_')) {
                key = key.replace('tr_chosen_', '');
                opts[key] = v;
            }
        });
        $(this).chosen(opts);
    });

    $('select.chosen-dropdown-color-picker')
        .chosen()
        .on('chosen:showing_dropdown', function(e) {
        var chosenElement = $(e.currentTarget.nextSibling);
        chosenElement.find('li.active-result').each(function(idx, el) {
            var t = $(el).text();
            if (t.length == 1) {
                $(el).addClass('color-group-' + _.toLower(t));
            }
        });
    });

    var popoverList = $('.popover-trigger').each(function(idx, el) {
        return new bootstrap.Popover(el);
    });

    var tooltipList = $('.tooltip-trigger').each(function(idx, el) {
        return new bootstrap.Tooltip(el);
    });

    // reporting - advanced filters
    var check_use_advanced = function() {
        var status = $('#id_use_advanced').is(':checked');
        $('#report-advanced-filters').toggle(status);
        $('#which-show').prop('disabled', status).trigger("chosen:updated");
    };
    $('#id_use_advanced').on('change', check_use_advanced);
    check_use_advanced();

    // report selector form
    $('#report-header-form').on('submit', function(e) {
        e.preventDefault();
        var batch_qs = '';
        var batch = $('#report-header-form #id_batch').val();
        var staff_user = $('#report-header-form #id_staff_user').val();
        var group_code = $('#report-header-form #id_group_code').val();
        var exclude_group_codes = $('#report-header-form #id_exclude_group_codes').val();
        var color_group = $('#report-header-form #id_color_group').val();
        var hold = $('#report-header-form #id_hold').val();
        var date = $('#report-header-form #id_date').val();
        var date_start = $('#report-header-form #id_date_start').val();
        var date_end = $('#report-header-form #id_date_end').val();
        var date_filter = $('#report-header-form #id_date_filter').val();
        var show_date_start = $('#report-header-form #id_show_date_start').val();
        var show_date_end = $('#report-header-form #id_show_date_end').val();
        var trans_date_start = $('#report-header-form #id_trans_date_start').val();
        var trans_date_end = $('#report-header-form #id_trans_date_end').val();
        var processed_date_start = $('#report-header-form #id_processed_date_start').val();
        var processed_date_end = $('#report-header-form #id_processed_date_end').val();
        var check_status = $('#report-header-form #id_check_status').val();
        var status = $('#report-header-form #id_status').val();
        var use_advanced = $('#report-header-form #id_use_advanced').is(':checked');
        var payment_method = $('#report-header-form #id_payment_method').val();
        var shadow_link_id = $('#report-header-form #id_shadow_link_id').val();
        var layout = $('#report-header-form #id_layout').val();
        var sort_a = $('#report-header-form #id_sort_a').val();
        var url = $("#which-report").val();

        if (($("#which-show").length > 0) && (! $("#which-show").is(':disabled'))) {
            url += "?show=" + $("#which-show").val();
        } else if ($("#id_shows").length > 0) {
            url += "?" + $("#id_shows").serialize();
        } else {
            url += "?";
        }
         
        if (batch) {
            url += '&batch=' + batch;
        }
        if (staff_user) {
            url += '&staff_user=' + staff_user;
        }
        if (group_code) {
            url += '&group_code=' + group_code;
        }
        if (exclude_group_codes) {
            url += '&' + $('#report-header-form #id_exclude_group_codes').serialize();
        }
        if (color_group) {
            url += '&color_group=' + color_group;
        }
        if (hold) {
            url += '&hold=' + hold;
        }
        if (date) {
            url += '&date=' + date;
        }
        if (date_start) {
            url += '&date_start=' + date_start;
        }
        if (date_end) {
            url += '&date_end=' + date_end;
        }
        if (date_filter) {
            url += '&date_filter=' + date_filter;
        }
        if (show_date_start) {
            url += '&show_date_start=' + show_date_start;
        }
        if (show_date_end) {
            url += '&show_date_end=' + show_date_end;
        }
        if (trans_date_start) {
            url += '&trans_date_start=' + trans_date_start;
        }
        if (trans_date_end) {
            url += '&trans_date_end=' + trans_date_end;
        }
        if (processed_date_start) {
            url += '&processed_date_start=' + processed_date_start;
        }
        if (processed_date_end) {
            url += '&processed_date_end=' + processed_date_end;
        }
        if (check_status) {
            url += '&check_status=' + check_status;
        }
        if (status) {
            url += '&status=' + status;
        }
        if (payment_method) {
            url += '&payment_method=' + payment_method;
        }
        if (shadow_link_id) {
            url += '&shadow_link_id=' + shadow_link_id;
        }
        if (layout) {
            url += '&layout=' + layout;
        }
        if (sort_a) {
            url += '&sort_a=' + sort_a;
        }
        if (use_advanced) {
            url += '&use_advanced=on';
        }

        window.location.href = url;
    });

    // report selector form
    $('.select-report-form').on('submit', function(e) {
        e.preventDefault();
        var url = $(this).find(".report-selector").val();
        window.location.href = url;
    });

    // jQuery plugin to prevent double submission of forms
    $('form.dsp-protected').on('submit', function(e){
        var $form = $(this);

        if ($form.data('submitted') === true) {
            // Previously submitted - don't submit again
            e.preventDefault();
        } else {
            // Mark it so that the next submit can be ignored
            $form.data('submitted', true);
        }
    });

    var reloadWithQueryStringVars = function(queryStringVars) {
        var existingQueryVars = location.search ? location.search.substring(1).split("&") : [],
            currentUrl = location.search ? location.href.replace(location.search,"") : location.href,
            newQueryVars = {},
            newUrl = currentUrl + "?";
        if(existingQueryVars.length > 0) {
            for (var i = 0; i < existingQueryVars.length; i++) {
                var pair = existingQueryVars[i].split("=");
                newQueryVars[pair[0]] = pair[1];
            }
        }
        if(queryStringVars) {
            for (var queryStringVar in queryStringVars) {
                newQueryVars[queryStringVar] = queryStringVars[queryStringVar];
            }
        }
        if(newQueryVars) {
            for (var newQueryVar in newQueryVars) {
                newUrl += newQueryVar + "=" + newQueryVars[newQueryVar] + "&";
            }
            newUrl = newUrl.substring(0, newUrl.length-1);
            window.location.href = newUrl;
        } else {
            window.location.href = location.href;
        }
    };
    $('.reloadPageWithQS').on('change', function(e) {
        e.preventDefault();
        var name = $(this).attr('name');
        var val = $(this).val();
        var opts = {};
        opts[name] = val;
        reloadWithQueryStringVars(opts);
    });

    // sticky table headers
    $('.report-table').stickyTableHeaders({
        fixedOffset: $('#topnav'),
        cacheHeaderHeight: true
    });

    // ask to confirm
    $(document).on('click', '.ask-to-confirm', function(e) {
        if (! window.confirm("Are you sure?")) {
            e.preventDefault();
        }
    });

    // Show seats
    $('.seats-reveal-trigger').on('click', function(e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var result_container = $(this).parents('.seat-locations-tr-container');
        $(this).children('.fa').addClass('fa-spinner fa-spin');
        $.getJSON(url, function(data, textStatus, jqXHR) {
            $(result_container).html(data['seat_locations']);
        });
    });

    $('.mass-seats-reveal-trigger').on('click', function(e) {
        e.preventDefault();
        $(this).parents('h4').parent().find('.seats-reveal-trigger').trigger('click');
    });

    // if a checkbox is readonly, also make it 'disabled' -
    // checkboxes are an edge case, in that 'readonly' doesn't actually prevent the checkbox from being checked;
    // we want to set 'disabled' for it (but not for most fields, because that breaks the form)
    $('input[type="checkbox"][readonly]').attr('disabled', true);

    // datetimepicker
    $(".datetimeinput").datetimepicker({format: 'yyyy-mm-dd hh:ii', 'autoclose': true, 'maxView': 3, 'minView': 0, 'showMeridian': true, 'minuteStep': 15});

    // 'reveal'
    $(document).on('click', '.reveal-trigger', function(e) {
        e.preventDefault();
        var val = $(this).data('tr-reveal-value');
        $(this).after(val);
        $(this).remove();
    });

    // reprice tool
    if ($('.edit-seatmap-link').length > 0) {
        var docheight = $(window).height();
        $('#edit-seats-modal-iframe').height(docheight * 0.5);

        $(document).on('click', '.edit-seatmap-link', function(e) {
            e.preventDefault();
            var href = $(this).attr('href') + '?modal=1'
            $('#edit-seats-modal-iframe').attr('src', href);
            $('#edit-seats-modal').modal('show');
        });

        $(document).on('click', '#edit-seats-modal .btn-primary', function(e) {
            var iframedoc = document.getElementById('edit-seats-modal-iframe').contentWindow.document;
            iframedoc.getElementById('edit-seats-form').submit();
        });

        document.addEventListener('gtx.refreshseats', function(e) {
            $('#edit-seats-modal').modal('hide');
            $('.refresh-seatmap-link').trigger('click');
        }, false);
    }

    // ticket counts report
    if ($('.show-hide-passes-trigger').length > 0) {
        $('.show-hide-passes-trigger').on('change', function() {
            $('.pass-container').toggle( $(this).is(':checked') );
        });
    };

    // clickable color swatches
    $(".in-report .swatch-clickable").on('click', function() {
        var cg = $(this).data('tr-color-group');
        var cg_text = cg ? cg.toString().toUpperCase() : '&nbsp';
        $(this).parents('.dropdown').find('.swatch-picker-dropdown-toggle .swatch').removeClass().addClass('swatch color-group-' + cg).html(cg_text);

        var tr_id = $(this).data('tr-id');
        var tr_show_id = $(this).data('tr-show-id');

        $.post('/api/v1/set-color-group/', {
            'show_id': tr_show_id,
            'color_group': cg,
            'tr_id': tr_id
        }, function(data, textStatus, jqXHR) {
            if (data != "OK") {
                var msg = 'Error saving';
                var h = createSnackHTML(msg, 'bg-danger text-white');
                var el = $(h).appendTo('.toast-container');
                var toast = new bootstrap.Toast(el);
                toast.show();
            }
        });
    });

    // when the user clicks on one of these, set the appropriate value for 'cmd';
    // the reason this isn't just a <button type="submit" name="..."> is that some form
    // submits happen from the jqValidate submitHandler, which doesn't know which button was clicked
    $('.cmd-setter').on('click', function(e) {
        $('input[name="cmd"]').val($(this).val());
    });

    // attendee list handlers
    $(document).on('change', '.attendee-list-item .attendee-item-tbd', function(e) {
        var val = $(this).is(':checked');
        $(this).parents('.attendee-list-item').find('.form-control').prop('disabled', val);
        $(this).parents('.attendee-list-item').find('.tbd-note').toggle(val);
        $(this).parents('.attendee-list-item').find('.attendee-detail-info').toggle(!val);
    });

    // superuser reveal
    $('.superuser-reveal').on('click', function(evt) {
        evt.preventDefault();
        $('.superuser-hide').removeClass('superuser-hide');
    });
});
